import { IAlert } from '@/shared/model/alert.model';
import { PromptDialogContent } from '@/shared/model/prompt-dialog-content.model';
import { GetterTree } from 'vuex';
import { AppStore } from './types';
import { RootState } from '@/shared/config/store/types';
import { ICustomer } from '@/shared/model/customer.model';
import { IModelSelection } from '@/shared/model/model-selection.model';
import { ICapaSelection } from '@/shared/model/capa-selection.model';

export const getters: GetterTree<AppStore, RootState> = {
  getSnackbar(state): boolean {
    return state.snackbar;
  },
  getSnackbarContent(state): IAlert[] {
    return state.snackbarContent;
  },
  getPromptDialog(state): boolean {
    return state.promptDialog;
  },
  getPromptDialogContent(state): PromptDialogContent {
    return state.promptDialogContent;
  },
  getCustomer(state): ICustomer {
    return state.customer;
  },
  getIsRound(state): boolean {
    return state.isRound;
  },
  getSavingCustomer(state): boolean {
    return state.savingCustomer;
  },
  getDiagramVisibility(state): IModelSelection {
    return state.diagramVisibility;
  },
  getCapaVisibility(state): ICapaSelection {
    return state.capaVisibility;
  },
  getShowedAlertForThisCustomer(state): boolean {
    return state.showedAlertForThisCustomer;
  },
};
