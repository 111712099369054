import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Watch } from 'vue-property-decorator';
import AppNavDrawer from '@/core/app-nav-drawer/app-nav-drawer.vue';
import AppNavbar from '@/core/app-navbar/app-navbar.vue';
import AppFooter from '@/core/app-footer/app-footer.vue';
import Snackbar from '@/shared/components/snackbar/snackbar.vue';
import PromptDialog from '@/shared/components/prompt-dialog/prompt-dialog.vue';
import Entities from '@/entities/entities.vue';
import '@/shared/config/dayjs';
import { Subscription } from 'rxjs';
import ApplicationNotificationService from '@/shared/socket/application-notification.service';
import AccountService from '@/account/account.service';
import { Authority } from '@/shared/security/authority';
import { ICustomer } from '@/shared/model/customer.model';

@Component({
  components: {
    Entities,
    AppNavDrawer,
    AppNavbar,
    AppFooter,
    Snackbar,
    PromptDialog,
  },
})
export default class App extends Vue {
  @Inject('applicationNotificationService') private applicationNotificationService: () => ApplicationNotificationService;
  @Inject('accountService') private accountService: () => AccountService;
  private showDrawer = false;
  private showUpdateNotification = false;
  private subscription?: Subscription;
  private licenseHasExpiredAlert = false;

  @Watch('$store.getters.account')
  public async onLogon(newValue, oldValue) {
    if ((await this.isAuthenticated()) && newValue !== null && oldValue === null) {
      await this.init();
    }
  }
  @Watch('customer')
  private handelChangeCustomer() {
    setTimeout(() => {
      if (this.customer != null && this.customer.license != null && !this.customer.license.isValid && !this.showedNoty) {
        this.licenseHasExpiredAlert = true;
        this.showedNoty = true;
      } else {
        this.licenseHasExpiredAlert = false;
      }
    }, 1000);
  }

  public async destroyed() {
    await this.applicationNotificationService().disconnect();
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  private async isAuthenticated() {
    return await this.accountService().hasAnyAuthorityAndCheckAuth(Authority.USER);
  }

  public async init() {
    this.showUpdateNotification = false;
    // this.applicationNotificationService().connect();
    // this.subscription = this.applicationNotificationService().subscribe(activity => {
    //   this.showUpdateNotification = true;
    // });
  }

  public refreshApplication() {
    location.reload();
  }

  private get showNavDrawer() {
    return this.$route.name !== 'Home' && this.$route.name !== 'Login';
  }

  private get isHome() {
    return this.$route.name == 'Home';
  }

  private set customer(newCustomer: ICustomer) {
    this.$store.dispatch('appStore/setCustomer', newCustomer).then(null);
  }

  private get customer(): ICustomer {
    return this.$store.getters['appStore/getCustomer'];
  }

  private set showedNoty(newShowedAlertForThisCustomer: boolean) {
    this.$store.dispatch('appStore/setShowedAlertForThisCustomer', newShowedAlertForThisCustomer).then(null);
  }

  private get showedNoty(): boolean {
    return this.$store.getters['appStore/getShowedAlertForThisCustomer'];
  }
}
