var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    {
      staticClass: "custom-app-bar",
      class: !_vm.isCalculatorRoute ? "border-bottom" : "",
      attrs: { app: "", flat: "" },
    },
    [
      _c(
        "v-row",
        {
          staticClass: "mx-3 mt-1 px-2",
          class: _vm.isCalculatorRoute ? "floating-header" : "",
          staticStyle: { height: "10vh" },
          attrs: { align: "center", justify: "center" },
        },
        [
          !_vm.isCalculatorRoute
            ? _c(
                "v-toolbar-title",
                { staticClass: "ml-5" },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0", attrs: { align: "center" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../../content/images/large_logo.svg"),
                          alt: "logo",
                          width: "220",
                        },
                      }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isCalculatorRoute ? _c("v-spacer") : _vm._e(),
          _vm._v(" "),
          _vm.customer && _vm.isCalculatorRoute
            ? _c(
                "v-row",
                {
                  staticClass: "ma-0",
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c("lazy-auth-image", {
                    attrs: {
                      size: "large",
                      "is-avatar": true,
                      id: _vm.customer.thumbnailId,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pa-0 ms-2", attrs: { justify: "center" } },
                    [
                      _vm.customer.firstPerson
                        ? _c(
                            "p",
                            { staticClass: "ma-0 text-h7 font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatPersonalInfo")(
                                    _vm.customer.firstPerson
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.customer.secondPerson
                        ? _c(
                            "p",
                            { staticClass: "ma-0 text-h7 font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatPersonalInfo")(
                                    _vm.customer.secondPerson
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasAddress
                        ? _c("p", { staticClass: "ma-0 font-14" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatAddress")(
                                  _vm.customer.propertyData.objectAddress
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _vm.isNotCustomerRoute && !_vm.isAdmin
            ? _c(
                "v-col",
                { staticStyle: { "max-width": "16%" }, attrs: { md: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "rounded-lg",
                      attrs: {
                        color: "primary",
                        elevation: "0",
                        loading: _vm.savingCustomer,
                        block: "",
                      },
                      on: { click: _vm.saveAndGoToCustomerList },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-account-multiple")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "ma-0 px-2" }, [_vm._v("kunden")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.customer != null &&
                  _vm.customer.customerId != null &&
                  _vm.isCalculatorRoute
                    ? _c(
                        "v-row",
                        {
                          staticClass: "ma-0 mt-1 subtitle-2",
                          attrs: { align: "center", justify: "space-between" },
                        },
                        [
                          _c("span", [_vm._v("Kd-ID:")]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.customer.customerId))]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }