import axios from 'axios';
import { Appointment, IAppointment } from '@/shared/model/appointment.model';
const baseApiUrl = 'api/calendar';
const appointmentApiUrl = 'api/appointments';

export default class CalendarService {
  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public syncWithGoogleCalendar(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/sync-google`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findAppointment(appointmentId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${appointmentApiUrl}/${appointmentId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public updatedAppointment(appointmentId: number, appointment: IAppointment): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${appointmentApiUrl}/${appointmentId}`, appointment)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteAppointment(appointmentId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${appointmentApiUrl}/${appointmentId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public sendNewAppointment(appointment: Appointment): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(appointmentApiUrl, appointment)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
