import axios from 'axios';
import { ICalculationParameters } from '@/shared/model/calculation-parameters.model';
import { IOfferingUploadVM } from '@/shared/model/offering-upload-VM.model';

const baseApiUrl = 'api/customers';
const calculationApiUrl = 'api/calculation-parameters';
export default class HistoryService {
  public retrieve(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${customerId}/history`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(customerId: number, historyId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${customerId}/history/${historyId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public initActiveCalculationParameter(): Promise<ICalculationParameters> {
    return new Promise<ICalculationParameters>((resolve, reject) => {
      axios
        .get(`${calculationApiUrl}/init-param`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public readDocument(documentId: number): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/document/read/${documentId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public sendDocument(offer: IOfferingUploadVM): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/history`, offer)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
