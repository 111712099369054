import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

const Calculator = () => import('@/calculator/calculator.vue');
const Customer = () => import('@/customer/customer.vue');
const Calendar = () => import('@/calendar/calendar.vue');

const ArchivedCustomer = () => import('@/archive-customers/archived-customers.vue');

const Notaries = () => import('@/entities/notary/notary.vue');
const ValueSources = () => import('@/entities/value-sources/value-sources.vue');

const Estimate = () => import('@/estimate/estimate.vue');

const SystemAnalyse = () => import('@/system-analyse/system-analyse.vue');

const Profile = () => import('@/profile/profile.vue');

export default [
  // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: { authorities: [Authority.GROUP, Authority.ROLE_EXTERNAL_MARKETER, Authority.MARKETER] },
  },
  {
    path: '/calculator',
    name: 'Calculator',
    component: Calculator,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/customer',
    name: 'customer',
    component: Customer,
    meta: { authorities: [Authority.GROUP, Authority.ROLE_EXTERNAL_MARKETER, Authority.MARKETER] },
  },
  {
    path: '/archived-customer',
    name: 'archived-customer',
    component: ArchivedCustomer,
    meta: { authorities: [Authority.GROUP] },
  },
  {
    path: '/notaries',
    name: 'notaries',
    component: Notaries,
    meta: { authorities: [Authority.GROUP] },
  },
  {
    path: '/value-sources',
    name: 'value-sources',
    component: ValueSources,
    meta: { authorities: [Authority.ADMIN, Authority.GROUP] },
  },
  {
    path: '/estimate',
    name: 'estimate',
    component: Estimate,
    meta: { authorities: [Authority.ADMIN, Authority.GROUP] },
  },
  {
    path: '/system-analyse',
    name: 'system-analyse',
    component: SystemAnalyse,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { authorities: [Authority.GROUP, Authority.MARKETER, Authority.ROLE_EXTERNAL_MARKETER] },
  },
];
