import { IAlert } from '@/shared/model/alert.model';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component
export default class Snackbar extends Vue {
  private get snackbar(): boolean {
    return this.$store.getters['appStore/getSnackbar'];
  }
  private set snackbar(newSnackbar: boolean) {
    this.$store.dispatch('appStore/setSnackbar', newSnackbar);
  }
  private get snackbarContent(): IAlert[] {
    return this.$store.getters['appStore/getSnackbarContent'];
  }
  private set snackbarContent(newSnackbarContent: IAlert[]) {
    this.$store.dispatch('appStore/setSnackbarContent', newSnackbarContent);
  }

  @Watch('snackbarContent')
  private onSnackbarContentChanged(oldValue: IAlert[], newValue: IAlert[]) {
    this.snackbar = newValue.length > 0;
  }
}
