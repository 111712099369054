import axios from 'axios';
import { ICalculationParameters } from '@/shared/model/calculation-parameters.model';
const analyseModel = 'api/analyse-model';
const analyseCapa = 'api/analyse-capa';

export default class SystemAnalyseService {
  public calculateCapa(parameter: ICalculationParameters): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(analyseCapa, parameter)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public calculateModel(parameter: ICalculationParameters): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(analyseModel, parameter)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
