// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import router from './router';
import * as config from './shared/config/config';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import AccountService from './account/account.service';

import '../content/scss/global.scss';
import '../content/scss/vendor.scss';

import CalculatorService from '@/calculator/calculator.service';
import MaritalStatusService from '@/entities/marital-status/marital-status.service';
import ProvinceService from '@/entities/province/province.service';
import ValueDeclarerService from '@/entities/value-declarer/value-declarer.service';
import DigitalValueService from '@/entities/digital-value/digital-value.service';
import CustomerService from '@/customer/customer.service';
import LifeExpectancyService from '@/entities/life-expectancy/life-expectancy.service';
import DocumentsService from '@/calculator/documents/documents.service';
import CalendarService from '@/calendar/calendar.service';
import MeetingNoteService from '@/calculator/note/meeting-note.service';
import HistoryService from '@/calculator/history/history.service';
import PricehubbleService from '@/calculator/pricehubble/pricehubble.service';
import VCurrencyField from 'v-currency-field';
import DeviationService from '@/entities/deviations/deviation.service';
import DocumentTypeService from '@/entities/document-type/document-type.service';
import ArchivedCustomersService from '@/archive-customers/archived-customers.service';
import AppUtils from '@/shared/util/app-utils';

import VueApexCharts from 'vue-apexcharts';
import * as GmapVue from 'gmap-vue';
import { GOOGLE_API_KEY } from '@/constants';
import ApplicationNotificationService from '@/shared/socket/application-notification.service';
import CustomerTypesService from '@/entities/customer-types/customer-types.service';
import LeadSourcesService from '@/entities/lead-sources/lead-sources.service';
import SecondLeadSourcesService from '@/entities/second-lead-sources/second-lead-sources.service';
import MarketingActionsService from '@/entities/marketing-actions/marketing-actions.service';
import SalesAreasService from '@/entities/sales-areas/sales-areas.service';
import SpecialLimitsService from '@/entities/special-limits/special-limits.service';
import NotaryService from '@/entities/notary/notary.service';
import ValueSourcesService from '@/entities/value-sources/value-sources.service';
import ChanceWeightService from '@/entities/chance-weight/chance-weight.service';
import EstimateService from '@/estimate/estimate.service';
import LeadParameterService from '@/entities/lead-parameter/lead-parameter.service';
import SystemEmailService from '@/entities/system-email/system-email.service';
import SystemAnalyseService from '@/system-analyse/system-analyse.service';
import DefaultDisplayValueService from '@/entities/default-display-value/default-display-value.service';
import LazyAuthImageService from '@/shared/components/lazy-auth-image/lazy-auth-image.service';
import PriceHubbleChartService from '@/calculator/pricehubble/pricehubble-chart/priceHubble-chart.service';
import OfferTypeService from '@/entities/offer-type/offer-type.service';
import GroupManagementService from '@/admin/group-management/group-management.service';
import GroupLicensesInfoService from '@/admin/group-management/group-management-dialog/group-licenses-info/group-licenses-info.service';
import domtoimage from 'dom-to-image-more';
import GroupUsersInfoService from '@/admin/group-management/group-management-dialog/group-users-info/group-users-info.service';
import UserManagementService from '@/admin/user-management/user-management.service';
import ResetAllSettingService from '@/entities/reset-all-setting/reset-all-setting.service';
/* tslint:disable */

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
Vue.use(Vue2Filters);
Vue.use(VueApexCharts);
Vue.use(domtoimage);

Vue.component('apexchart', VueApexCharts);

Vue.use(VCurrencyField, {
  locale: 'de-DE',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
});
Vue.use(GmapVue, {
  load: {
    key: GOOGLE_API_KEY,
    libraries: 'places',
  },

  installComponents: true,
});

Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
const vuetify = config.initVuetify(Vue);

const store = config.initVueXStore(Vue);

const accountService = new AccountService(store, router);

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
      if (!value) {
        sessionStorage.setItem('requested-url', to.fullPath);
        next('/');
      } else {
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
const vue = new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    documentService: () => new DocumentsService(),
    calculatorService: () => new CalculatorService(),
    valueDeclarerService: () => new ValueDeclarerService(),
    digitalValueService: () => new DigitalValueService(),
    maritalStatusService: () => new MaritalStatusService(),
    provinceService: () => new ProvinceService(),
    customerService: () => new CustomerService(),
    lifeExpectancyService: () => new LifeExpectancyService(),
    calendarService: () => new CalendarService(),
    meetingNoteService: () => new MeetingNoteService(),
    historyService: () => new HistoryService(),
    pricehubbleService: () => new PricehubbleService(),
    deviationService: () => new DeviationService(),
    documentTypeService: () => new DocumentTypeService(),
    archivedCustomerService: () => new ArchivedCustomersService(),
    customerTypesService: () => new CustomerTypesService(),
    leadSourcesService: () => new LeadSourcesService(),
    secondLeadSourcesService: () => new SecondLeadSourcesService(),
    marketingActionsService: () => new MarketingActionsService(),
    saleAreasService: () => new SalesAreasService(),
    notaryService: () => new NotaryService(),
    specialLimitsService: () => new SpecialLimitsService(),
    appUtils: () => new AppUtils(),
    applicationNotificationService: () => new ApplicationNotificationService(),
    valueSourceService: () => new ValueSourcesService(),
    chanceWeightService: () => new ChanceWeightService(),
    estimateService: () => new EstimateService(),
    leadParameterService: () => new LeadParameterService(),
    systemEmailService: () => new SystemEmailService(),
    systemAnalyseService: () => new SystemAnalyseService(),
    defaultDisplayValueService: () => new DefaultDisplayValueService(),
    lazyAuthImageService: () => new LazyAuthImageService(),
    priceHubbleChartService: () => new PriceHubbleChartService(),
    offerTypeService: () => new OfferTypeService(),
    groupManagementService: () => new GroupManagementService(),
    groupLicensesInfoService: () => new GroupLicensesInfoService(),
    groupUsersInfoService: () => new GroupUsersInfoService(),
    userManagementService: () => new UserManagementService(),
    resetAllSettingsService: () => new ResetAllSettingService(),
  },
  store,
  vuetify,
});
