import axios from 'axios';

import { IAge } from '@/shared/model/age.model';
import { AgeEdit, IAgeEdit } from '@/shared/model/age-edit.model';

const baseApiUrl = 'api/ages';

export default class AgeService {
  public find(id: number): Promise<IAge> {
    return new Promise<IAge>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<AgeEdit[]> {
    return new Promise<AgeEdit[]>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/age-edits`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IAgeEdit): Promise<IAgeEdit> {
    return new Promise<IAge>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
