import axios from 'axios';

import { IModelASystemParameter } from '@/shared/model/model-a-system-parameter.model';

const baseApiUrl = 'api/model-a-system-parameters';

export default class ModelASystemParameterService {
  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IModelASystemParameter): Promise<IModelASystemParameter> {
    return new Promise<IModelASystemParameter>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public reset(parameterId: number): Promise<IModelASystemParameter> {
    return new Promise<IModelASystemParameter>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/reset/${parameterId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
