var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("app-nav-drawer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showNavDrawer,
            expression: "showNavDrawer",
          },
        ],
      }),
      _vm._v(" "),
      _c("app-navbar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showNavDrawer,
            expression: "showNavDrawer",
          },
        ],
      }),
      _vm._v(" "),
      _c(
        "v-main",
        { staticClass: "custom-layout pa-0" },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _c("app-footer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showNavDrawer,
            expression: "showNavDrawer",
          },
        ],
      }),
      _vm._v(" "),
      _c("prompt-dialog"),
      _vm._v(" "),
      _c("snackbar"),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { color: "grey", timeout: "-1", top: "", right: "" },
          model: {
            value: _vm.showUpdateNotification,
            callback: function ($$v) {
              _vm.showUpdateNotification = $$v
            },
            expression: "showUpdateNotification",
          },
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-h6", attrs: { cols: "12" } }, [
                _vm._v(
                  " Es gibt ein neues Update. Bitte aktualisieren Sie die Seite. "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mb-0" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showUpdateNotification = false
                    },
                  },
                },
                [_vm._v(" Ignorieren ")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "success" },
                  on: { click: _vm.refreshApplication },
                },
                [_vm._v(" Aktualisieren ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "-1", color: "grey", bottom: "", right: "" },
          model: {
            value: _vm.licenseHasExpiredAlert,
            callback: function ($$v) {
              _vm.licenseHasExpiredAlert = $$v
            },
            expression: "licenseHasExpiredAlert",
          },
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-h6", attrs: { cols: "12" } }, [
                _vm._v(
                  "\n        Ihr Zugriff auf diesen kunde wurde eingeschränkt, da die Lizenz für diesen kunde abgelaufen ist\n      "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mb-0", attrs: { align: "end", justify: "end" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.licenseHasExpiredAlert = false
                    },
                  },
                },
                [_vm._v(" Alles klar! ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }