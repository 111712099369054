import axios from 'axios';
import { ISystemEmail } from '@/shared/model/system-email.model';
import { EmailType } from '@/shared/model/enumerations/email-type.model';

const baseApiUrl = 'api/system-emails';

export default class SystemEmailService {
  public retrieve(): Promise<ISystemEmail[]> {
    return new Promise<ISystemEmail[]>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getByType(type: EmailType): Promise<ISystemEmail[]> {
    return new Promise<ISystemEmail[]>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${type}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public create(entity: ISystemEmail): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(baseApiUrl, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public find(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
