var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "30rem" },
      model: {
        value: _vm.promptDialog,
        callback: function ($$v) {
          _vm.promptDialog = $$v
        },
        expression: "promptDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "radius-10", attrs: { outlined: "", elevation: "0" } },
        [
          _c("v-card-title", { staticClass: "tertiary py-3" }, [
            _vm._v(" " + _vm._s(_vm.promptDialogContent.title) + " "),
          ]),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "pa-5 my-5" }, [
            _c("p", { staticClass: "text-center ma-0" }, [
              _vm._v(_vm._s(_vm.promptDialogContent.text)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                { staticClass: "ma-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", block: "" },
                          on: { click: _vm.resolve },
                        },
                        [_vm._v(" " + _vm._s(_vm.promptDialogContent.resolve))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", outlined: "", block: "" },
                          on: { click: _vm.reject },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.promptDialogContent.reject) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }