import axios from 'axios';
import { IGroup } from '@/shared/model/group.model';
const baseUrl = 'api/groups';

export default class GroupManagementService {
  public retrieve(): Promise<IGroup[]> {
    return new Promise<IGroup[]>((resolve, reject) => {
      axios
        .get(baseUrl)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveAllExternalMarketers(): Promise<IGroup[]> {
    return new Promise<IGroup[]>((resolve, reject) => {
      axios
        .get(`${baseUrl}/marketers/external`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public find(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public create(entity: IGroup): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(baseUrl, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public edit(id: number, entity: IGroup): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${baseUrl}/${id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public sendCalendarToken(base64File: string, groupId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseUrl}/calendar-token/${groupId}`, base64File)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public changeStatus(active: boolean, groupId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseUrl}/${active ? 'activate' : 'deactivate'}/${groupId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
