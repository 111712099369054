import axios from 'axios';
const baseApiUrl = 'api/price-hubble';

export default class PriceHubbleChartService {
  public getValuation(dossierId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/dossier/${dossierId}/valuation`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
