var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pa-0 ma-0" },
    [
      _vm.downloading
        ? _c("v-skeleton-loader", {
            class: _vm.size + "-thumbnail-image",
            attrs: { type: "card" },
          })
        : !_vm.isAvatar
        ? _c("img", {
            class: _vm.size + "-thumbnail-image",
            attrs: { src: _vm.imageSrc, alt: "thumbnail" },
          })
        : _vm.isAvatar && _vm.id != null
        ? _c("img", {
            class: _vm.size + "-thumbnail-image",
            attrs: { src: _vm.imageSrc, alt: "avatar" },
          })
        : _c("img", {
            class: _vm.size + "-thumbnail-image",
            attrs: {
              src: require("../../../../content/images/no-image.png"),
              alt: "no image",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }