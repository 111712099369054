import axios from 'axios';
import { ILicense } from '@/shared/model/license.model';
const baseUrl = 'api/licenses';

export default class GroupLicensesInfoService {
  public retrieve(groupId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseUrl}/group/${groupId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public find(licenseId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseUrl}/${licenseId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(licenseId: number, license: ILicense): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${baseUrl}/${licenseId}`, license)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public delete(licenseId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseUrl}/${licenseId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public create(entity: ILicense): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(baseUrl, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
