import axios from 'axios';
import { IUser } from '@/shared/model/user.model';
const baseUrl = 'api/groups/marketers';
const userApi = 'api/admin/users';

export default class UserManagementService {
  public retrieve(groupId: number): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve, reject) => {
      axios
        .get(`${baseUrl}/${groupId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(user: IUser): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(userApi, user)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public update(user: IUser): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(userApi, user)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public delete(id: number): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve, reject) => {
      axios
        .delete(`${userApi}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      axios
        .get(`${userApi}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
