import axios from 'axios';

import { ILiveDuration } from '@/shared/model/live-duration.model';

const baseApiUrl = 'api/live-durations';

export default class LiveDurationService {
  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ILiveDuration): Promise<ILiveDuration> {
    return new Promise<ILiveDuration>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
