import axios from 'axios';
const baseApiUrl = 'api/customers/document/thumbnail';
export default class LazyAuthImageService {
  public getThumbnailWithId(thumbnailId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${thumbnailId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
