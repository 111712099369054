import { ISalesArea } from '@/shared/model/sales-area.model';
import axios from 'axios';

export default class ResetAllSettingService {
  public resetAllSettings(): Promise<ISalesArea> {
    return new Promise<ISalesArea>((resolve, reject) => {
      axios
        .get(`api/groups/reset`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
