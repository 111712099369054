import { Component, Vue } from 'vue-property-decorator';
import { ICustomer } from '@/shared/model/customer.model';
import LazyAuthImage from '@/shared/components/lazy-auth-image/lazy-auth-image.vue';
import { Authority } from '@/shared/security/authority';

@Component({
  components: { LazyAuthImage },
})
export default class AppNavbar extends Vue {
  changeTheme() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }

  private toggleDrawer() {
    this.$emit('toggleDrawer');
  }

  private closeDrawer() {
    this.$emit('closeDrawer');
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  private get isNotCustomerRoute() {
    return this.$route.fullPath != '/customer';
  }
  private get isCalculatorRoute() {
    return this.$route.fullPath === '/calculator';
  }
  private get customer(): ICustomer {
    return this.$store.getters['appStore/getCustomer'];
  }

  private get showCustomerId() {
    return this.customer != null && this.customer.customerId != null && this.$route.path == '/calculator';
  }

  private get hasAddress() {
    return (
      this.customer != null &&
      this.customer.propertyData != null &&
      this.customer.propertyData.objectAddress &&
      this.customer.propertyData.objectAddress.zipcode != null
    );
  }
  private get isAdmin() {
    return (
      this.$store.getters.account != null &&
      this.$store.getters.account.authorities != null &&
      this.$store.getters.account.authorities.includes(Authority.ADMIN)
    );
  }

  private get hasPartner() {
    return this.customer != null && this.customer.secondPerson != null && this.customer.secondPerson.firstname != null;
  }

  private async saveAndGoToCustomerList() {
    if (this.isCalculatorRoute) {
      this.savingCustomer = true;
    } else {
      await this.$router.push('/customer');
    }
  }

  private get savingCustomer(): boolean {
    return this.$store.getters['appStore/getSavingCustomer'];
  }

  private set savingCustomer(newSavingCustomer: boolean) {
    this.$store.dispatch('appStore/setSavingCustomer', newSavingCustomer).then(null);
  }
}
