import axios from 'axios';

import { IValueDeclarer } from '@/shared/model/value-declarer.model';

const basePublicApiUrl = 'api/public/value-declarers';
const baseApiUrl = 'api/value-declarers';

export default class ValueDeclarerService {
  public find(id: number): Promise<IValueDeclarer> {
    return new Promise<IValueDeclarer>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(basePublicApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IValueDeclarer): Promise<IValueDeclarer> {
    return new Promise<IValueDeclarer>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IValueDeclarer): Promise<IValueDeclarer> {
    return new Promise<IValueDeclarer>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
