import Vue from 'vue';
import dayjs from 'dayjs';
import { IAddress } from '@/shared/model/address.model';
import { Authority } from '@/shared/security/authority';

export const DATE_FORMAT_TEXT = 'DD.MM.YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm';

export const DATE_TIME_LONG_FORMAT = 'YYYY-MM-DDTHH:mm';

export function initFilters() {
  Vue.filter('formatJustDateText', value => {
    if (value) {
      return dayjs(value).format(DATE_FORMAT_TEXT);
    }
    return '';
  });
  Vue.filter('formatJustTime', value => {
    if (value) {
      return dayjs(value).format(TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('formatJustDate', value => {
    if (value) {
      return dayjs(value).format(DATE_FORMAT);
    }
    return '';
  });
  Vue.filter('formatDate', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('duration', value => {
    if (value) {
      const formatted = dayjs.duration(value).humanize();
      if (formatted) {
        return formatted;
      }
      return value;
    }
    return '';
  });
  Vue.filter('formatNumber', value => {
    return new Intl.NumberFormat('de-DE').format(value);
  });
  Vue.filter('formatNumberText', value => {
    if (value) {
      return new Intl.NumberFormat('de-DE').format(value);
    }

    return '0';
  });
  Vue.filter('thresholdFormat', value => {
    if (value) {
      const str = value.toString().replaceAll(',', '.');
      return new Intl.NumberFormat('de-DE').format(str);
    }

    return null;
  });
  Vue.filter('formatNumber2', value => {
    if (value) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(value);
    }

    return '';
  });
  Vue.filter('formatPercent', value => {
    return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(value) + '%';
  });
  Vue.filter('formatNumberWithLetter', value => {
    let result = null;
    value / 1000000 > 1
      ? (result = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(Math.ceil(value / 1000000)) + 'MB')
      : (result = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(Math.ceil(value / 1000)) + 'KB');
    return result;
  });
  Vue.filter('formatJustPrice', value => {
    if (value) {
      return new Intl.NumberFormat('de-DE').format(value);
    }
    return '';
  });
  Vue.filter('formatPersonalInfo', value => {
    if (value) {
      return formatString(value.firstname) + ' ' + formatString(value.lastname) + (value.age ? formatString(' (' + value.age + ')') : '');
    }
    return '';
  });
  Vue.filter('formatAddress', value => {
    if (value) {
      const address: IAddress = value;
      return (
        formatString(address.street) +
        ' ' +
        formatString(address.houseNumber) +
        ', ' +
        formatString(String(address.zipcode ?? '')) +
        ' ' +
        formatString(address.city)
      );
    }
    return '';
  });
  Vue.filter('formatAuthorities', value => {
    let result = '';

    if (value != null) {
      value.map((item, index) => {
        if (item === Authority.USER) {
          value.splice(index, 1);
        }
        if (item === Authority.GROUP) {
          result = 'Fachadmin';
        }
        if (item === Authority.MARKETER) {
          result = 'Makler';
        }
        if (item === Authority.API) {
          result = 'Lead';
        }
        if (item === Authority.ROLE_EXTERNAL_MARKETER) {
          result = 'Externer makler';
        }
      });
    }

    return result;
  });

  const formatString = (str: string) => (str ? str : '');
}
