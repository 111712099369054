import axios from 'axios';

import { ILifeExpectancy } from '@/shared/model/life-expectancy.model';
import { ICalculationParameters } from '@/shared/model/calculation-parameters.model';
import { FamilyMember } from '@/shared/model/enumerations/family-member.model';
import _default from 'chart.js/dist/plugins/plugin.decimation';
import algorithm = _default.defaults.algorithm;

const baseApiUrl = 'api/life-expectancies';

export default class LifeExpectancyService {
  public find(id: number): Promise<ILifeExpectancy> {
    return new Promise<ILifeExpectancy>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveForAges(familyMember: FamilyMember, age: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${baseApiUrl}/for-ages`,
          {
            familyMember: familyMember,
            age: age,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ILifeExpectancy): Promise<ILifeExpectancy> {
    return new Promise<ILifeExpectancy>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ILifeExpectancy): Promise<ILifeExpectancy> {
    return new Promise<ILifeExpectancy>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ILifeExpectancy): Promise<ILifeExpectancy> {
    return new Promise<ILifeExpectancy>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
