import axios from 'axios';
import { IEstimate } from '@/shared/model/estimate.model';
const baseApiUrl = 'api/estimate';

export default class EstimateService {
  public retrieve(): Promise<IEstimate> {
    return new Promise<IEstimate>((resolve, reject) => {
      axios
        .post(baseApiUrl)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
