import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop, Watch } from 'vue-property-decorator';
import LazyAuthImageService from '@/shared/components/lazy-auth-image/lazy-auth-image.service';

@Component
export default class LazyAuthImage extends Vue {
  @Inject('lazyAuthImageService') private lazyAuthImageService: () => LazyAuthImageService;

  @Prop() id: number;
  @Prop() isAvatar: boolean;
  @Prop() size: string;

  @Watch('id')
  async handelReGenerate() {
    await this.getData();
  }

  private imageSrc = '../../../../content/images/no-image.png';
  private downloading = true;
  async mounted() {
    await this.getData();
  }

  private async getData() {
    if (this.id) {
      await this.getImageData();
    } else {
      this.downloading = false;
    }
  }

  private async getImageData() {
    const base64Image = await this.lazyAuthImageService().getThumbnailWithId(this.id);
    this.imageSrc = 'data:image/jpeg;base64,' + base64Image;
    this.downloading = false;
  }
}
