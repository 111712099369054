import axios from 'axios';
import { ICalculationParameters } from '@/shared/model/calculation-parameters.model';

export default class CalculatorService {
  public getModelScenarioData(calculationParameters: ICalculationParameters): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`api/calculate-model`, calculationParameters)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public getCapaScenarioData(calculationParameters: ICalculationParameters): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`api/calculate-capa`, calculationParameters)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getCapaScenarioDataWithHistory(calculationParameters: ICalculationParameters, parameterHistoryId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`api/calculate-capa/history/${parameterHistoryId}`, calculationParameters)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getModelScenarioDataWithHistory(calculationParameters: ICalculationParameters, parameterHistoryId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`api/calculate-model/history/${parameterHistoryId}`, calculationParameters)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
